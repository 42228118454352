<!-- 可开票订单 -->
<template>
  <page-layout>
    <a-card>
      <a-row type="flex" :gutter="[12, 12]" justify="space-between">
        <a-col>
          <a-button type="primary" @click="batchInvoice">批量申请开票</a-button>
        </a-col>
        <a-col>
          <a-form-model :model="form" layout="inline">
            <a-form-model-item label="申请状态">
              <a-select
                v-model="form.invoiceStatus"
                style="width: 200px"
                placeholder="请选择"
                @change="getList"
              >
                <a-select-option
                  :value="item.value"
                  v-for="(item, index) in statusList"
                  :key="index"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="订单时间">
              <a-range-picker @change="onOrderDateChange" style="width: 220px">
                <a-icon slot="suffixIcon" type="calendar" />
              </a-range-picker>
            </a-form-model-item>
            <a-form-model-item label="申请时间">
              <a-range-picker @change="onChange" style="width: 220px">
                <a-icon slot="suffixIcon" type="calendar" />
              </a-range-picker>
            </a-form-model-item>
            <a-form-model-item label="">
              <a-input-search
                allow-clear
                placeholder="输入订单编号/发票抬头搜索"
                @search="onSearch"
                style="width: 220px"
              />
            </a-form-model-item>
          </a-form-model>
        </a-col>
      </a-row>
      <a-alert type="success" closable>
        <div slot="description">
          <a-icon type="info-circle" style="color: #44b549" />
          <span class="total">
            您有{{ countData.totalCount }}个订单可开票，已开票{{
              countData.okCount
            }}个，未开票{{ countData.daiCount }}个！
          </span>
          <span class="notice">
            注意：发票可在“我的发票”/收票邮箱自行查询下载打印；如需专票，申请时备注选择“数电专票”即可！
          </span>
        </div>
      </a-alert>
      <a-table
        :columns="columns"
        :row-key="
          (record) => record.id + '|' + record.payPrice + '|' + record.orderId
        "
        :data-source="data"
        :pagination="pagination"
        :loading="loading"
        style="margin-top: 16px"
        :scroll="{ x: 1500 }"
        bordered
        :row-selection="{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange,
          getCheckboxProps: (record) => ({
            props: {
              disabled:
                (record.isInvoice != 0 && record.isInvoice != 3) ||
                !record.useInvoice,
            },
          }),
        }"
        @change="handleTableChange"
      >
        <template slot="index" slot-scope="text, record, index">
          {{ index + 1 + (form.page - 1) * form.limit }}
        </template>
        <template slot="action" slot-scope="text, record">
          <router-link :to="'/bookBuy/order/orderDetail?id=' + record.orderId">
            查看订单
          </router-link>
        </template>
      </a-table>
    </a-card>

    <a-modal
      title="申请开票"
      :visible="applyVisible"
      :confirm-loading="confirmLoading"
      @ok="submitApply"
      @cancel="handleCancelApply"
      :width="868"
    >
      <div class="font12">
        <div class="new_flex">
          <span class="text_r lable">开票订单编号：</span>
          <div class="">
            <span v-if="orderList.length <= 3">{{ orderList.join(",") }}</span>
            <span v-else>{{ orderList.slice(0, 3).join(",") }}</span>
            <span class="total_order">共计：{{ orderList.length }}个订单</span>
          </div>
        </div>
        <div class="mart10 new_flex">
          <span class="text_r lable">开票订单金额：</span>
          <div>¥{{ totalOrderPrice }}元</div>
        </div>
        <div class="mart10 new_flex">
          <span class="text_r lable">发票类型：</span>数电普票
        </div>
        <div class="wrap new_flex mart10">
          <span class="text_r lable" style="flex-shrink: 0">
            选择开票信息：
          </span>
          <a-row class="pay_type ml5">
            <a-col
              class="type"
              :span="12"
              :class="applyForm.invoiceId == item.id ? 'active' : ''"
              v-for="item in allInvoiceList"
              :key="item.id"
              @click="type(item.id)"
            >
              <div>
                <div class="blod font14">
                  {{ item.headerType == 1 ? item.invoiceName : item.firmName }}
                  <span class="block">{{
                    item.headerType == 1 ? "个人" : "企业"
                  }}</span>
                </div>
                <div class="font12 mart5" v-if="item.headerType == 2">
                  企业税号:{{ item.firmDutyNumber }}
                </div>
                <div class="font12 mart5" v-if="item.headerType == 1">
                  创建时间:{{ item.addTime }}
                </div>
              </div>
              <a-icon type="check-circle" theme="filled" class="gray" />
            </a-col>
            <a-col class="type1 cur" :span="12" @click="addBilling">
              <div>
                <a-icon type="plus-circle" theme="filled" />
                <span class="marginL8">新增发票抬头</span>
              </div>
            </a-col>
          </a-row>
        </div>
        <div class="mart10">
          <span class="text_r lable">发票内容：</span>商品明细
        </div>
        <div class="mart10 new_flex">
          <span class="text_r lable">申请备注：</span>
          <!-- <a-textarea
            placeholder="如需开具纸质专票，请备注：开专票"
            :rows="4"
            v-model="applyForm.remark"
          /> -->
          <a-select
            size="small"
            default-value="数电专票"
            style="width: 200px"
            v-model="applyForm.remark"
            placeholder="请选择"
            allow-clear
          >
            <a-select-option value="数电专票"> 数电专票 </a-select-option>
          </a-select>
        </div>
      </div>
    </a-modal>
    <add-invioce ref="addInvioce" @subSuccess="subSuccess" :id="selectId" />
  </page-layout>
</template>

<script>
import PageLayout from "@/layouts/PageLayout";
import addInvioce from "../components/addInvioce.vue";
import { invoiceList, invoiceAll, orderBatchApply } from "@/api/invoice";
import { math } from "@/utils/math.js";
export default {
  name: "",
  components: { PageLayout, addInvioce },
  data() {
    return {
      form: {
        page: 1,
        limit: 10,
        invoiceStatus: "",
        orderTime: "",
        addTime: "",
        keyword: "",
      },
      statusList: [
        {
          value: "",
          label: "不限",
        },
        {
          value: 0,
          label: "未开票",
        },
        {
          value: 3,
          label: "审核中",
        },
        {
          value: 2,
          label: "审核不通过",
        },
        {
          value: 1,
          label: "已开票",
        },
      ],
      columns,
      data: [],
      loading: false,
      pagination: {
        showQuickJumper: true,
        showSizeChanger: true,
        total: 0,
      },
      selectedRowKeys: [], //开票id
      applyVisible: false,
      confirmLoading: false,
      mailInfo: {},
      allInvoiceList: [],
      applyForm: {
        invoiceId: "",
        remark: "",
      },
      orderList: [],
      orderIdList: [],
      totalOrderPrice: 0, // 开票订单金额
      selectId: 0,
      countData: {
        count: 0,
        daiCount: 0,
        okCount: 0,
        totalCount: 0,
      },
    };
  },
  created() {
    this.getList();
    this.getInvoiceInfoList();
  },
  methods: {
    onOrderDateChange(date, dateString) {
      this.form.orderTime = dateString[0]
        ? dateString[0] + "/" + dateString[1]
        : "";
      this.getList();
    },
    onChange(date, dateString) {
      this.form.addTime = dateString[0]
        ? dateString[0] + "/" + dateString[1]
        : "";
      this.getList();
    },
    onSearch(e) {
      this.form.keyword = e;
      this.getList();
    },
    subSuccess() {
      this.getInvoiceInfoList();
    },
    batchInvoice() {
      if (this.selectedRowKeys.length > 0) {
        this.applyVisible = true;
      } else {
        this.$message.warning("请选择需要开票的订单");
      }
    },
    type(id) {
      this.applyForm.invoiceId = id;
    },
    // 新增抬头
    addBilling() {
      this.selectId = 0;
      this.$refs.addInvioce.visible = true;
      this.$refs.addInvioce.isEdit = false;
    },
    getInvoiceInfoList() {
      invoiceAll().then((res) => {
        this.allInvoiceList = res.data.data.list;
        this.applyForm.invoiceId =
          res.data.data.list && res.data.data.list.length > 0
            ? res.data.data.list[0].id
            : "";
      });
    },
    handleCancelApply() {
      this.applyVisible = false;
    },
    submitApply() {
      let params = {
        invoiceId: this.applyForm.invoiceId,
        remark: this.applyForm.remark || "",
        orderIds: this.orderIdList.join(","),
        logIds: "",
      };
      orderBatchApply(params)
        .then(() => {
          this.$message.success("申请已提交");
          this.getList();
          this.selectedRowKeys = [];
          this.orderList = [];
          this.orderIdList = [];
          this.totalOrderPrice = 0;
        })
        .finally(() => {
          this.applyVisible = false;
        });
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      let price = 0;
      this.orderList = selectedRows.map((item) => {
        price = math.add(
          math.subtract(
            parseFloat(item.payPrice),
            parseFloat(item.returnPrice)
          ),
          price
        );
        return item.orderId;
      });
      this.orderIdList = selectedRows.map((item) => item.id);
      this.totalOrderPrice = price;
    },
    getList() {
      this.loading = true;
      invoiceList(this.form)
        .then((res) => {
          this.data = res.data.data.list;
          this.pagination.total = res.data.data.count;
          this.countData = {
            count: res.data.data.count,
            daiCount: res.data.data.daiCount,
            okCount: res.data.data.okCount,
            totalCount: res.data.data.totalCount,
          };
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleTableChange(page) {
      this.form.page = page.current;
      this.form.limit = page.pageSize;
      this.pagination = page;
      this.getList();
    },
  },
};
const columns = [
  {
    title: "序号",
    dataIndex: "index",
    align: "center",
    scopedSlots: { customRender: "index" },
    width: 70,
  },
  {
    title: "订单编号",
    dataIndex: "orderId",
    key: "orderId",
    align: "center",
    width: 200,
  },
  {
    title: "订单金额",
    dataIndex: "totalPrice",
    align: "center",
    key: "totalPrice",
    customRender: (text) => {
      return "￥" + text;
    },
  },
  {
    title: "订单运费",
    dataIndex: "payPostage",
    align: "center",
    key: "payPostage",
    customRender: (text) => {
      return "￥" + text.toFixed(2);
    },
  },
  {
    title: "申请发票类型",
    dataIndex: "invoiceType",
    align: "center",
    key: "invoiceType",
    customRender: (text) => {
      return text == "/" ? text : text == 1 ? "普票" : "专票";
    },
  },
  {
    title: "申请发票抬头",
    dataIndex: "name",
    align: "center",
    key: "invoiceName",
    customRender: (text, record) => {
      return record.firmName || record.invoiceName;
    },
  },
  {
    title: "申请时间",
    align: "center",
    dataIndex: "addTime",
    key: "addTime",
    width: 180,
  },
  {
    title: "状态",
    dataIndex: "isInvoice",
    align: "center",
    key: "isInvoice",
    customRender: (text) => {
      return text == 0
        ? "未开票"
        : text == 1
        ? "审核中"
        : text == 2
        ? "审核通过"
        : text == 3
        ? "开票拒绝"
        : text == 4
        ? "开票完成"
        : "已邮寄待收货";
    },
  },
  {
    title: "拒绝理由",
    dataIndex: "failMsg",
    align: "center",
    key: "failMsg",
  },
  {
    title: "操作",
    dataIndex: "action",
    align: "center",
    key: "action",
    scopedSlots: { customRender: "action" },
    width: 120,
    fixed: "right",
  },
];
</script>
<style scoped lang="less">
div /deep/ .ant-alert-with-description.ant-alert-no-icon {
  padding: 5px 30px 8px 10px;
  margin-top: 16px;
}

div /deep/ .ant-alert-with-description .ant-alert-close-icon {
  top: 10px;
}

.total {
  margin-left: 6px;
  font-size: 12px;
  color: #383838;
}

.notice {
  color: #3ab887;
  font-size: 12px;
  font-weight: bold;
  margin-left: 10px;
}

.new_flex {
  display: flex;
}

.lable {
  width: 110px;
  display: inline-block;
  flex-shrink: 0;
}

.text_r {
  text-align: right;
}

.pay_type {
  width: 100%;
  color: #333;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .type {
    width: 337px;
    padding: 15px;
    min-height: 86px;
    border: 1px solid #eee;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 15px;
    margin-bottom: 15px;

    .block {
      margin-left: 3px;
      padding: 4px;
      color: @primary-color;
      font-size: 12px;
      background-color: rgba(58, 184, 135, 0.1);
      border-radius: 4px;
    }

    i {
      font-size: 23px;
    }
  }

  .type1 {
    width: 337px;
    padding: 15px;
    min-height: 86px;
    border: 1px solid #eee;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    margin-bottom: 15px;
    cursor: pointer;

    div {
      display: flex;
      align-items: center;

      i {
        font-size: 18px;
      }
    }
  }

  .type.active {
    border: 1px solid @primary-color;

    i {
      color: @primary-color !important;
    }
  }
}

.blod {
  font-weight: bold;
}

.total_order {
  margin-left: 16px;
  color: #3ab887;
}
</style>
